<template>
  <section>
    <kn-table
      v-if="!showInactive"
      key="act"
      :headers="headers"
      :items="items"
      :loading="loading"
      tableTitle="Alumnos"
      newButtonLabel="Agregar alumno"
      :showBottomAction="true"
      @action2="disableStudent"
      @showAction="showInactive = true"
    />
    <kn-inactive-items-table
      v-if="showInactive"
      key="iat"
      :headers="headers" 
      :items="inactiveItems"
      tableTitle="Alumnos" 
      newButtonLabel="Agregar alumno"
      :showBottomAction="true"
      @action2="enableStudent"
      @showAction="showInactive = false"
    />
  </section>
</template>

<script>
///
/// ==================================
/// AUTOR: Tania Lucero Marin Sosa
/// RESEÑANTE: Arturo López López
/// FECHA: 03/05/2022
/// COMENTARIOS:
/// - Se agrego en computed el mapState para usar id de institución educativa
///   de acuerdo al usuario logueado
/// - Pendiente disminuir los api methods (usando uno genérico).
/// ==================================
///
import { mapMutations, mapState } from 'vuex';
import KnTable from '@/components/tables/KnTable.vue';
import KnInactiveItemsTable from '@/components/tables/KnInactiveItemsTable.vue';
import { apiMixin } from '@/mixins/apiMixin.js'
import { dateUtilsMixin } from '@/mixins/dateUtilsMixin.js'
export default {
  components: {
    KnTable,
    KnInactiveItemsTable
  },
  mixins: [ apiMixin, dateUtilsMixin ],
  data() {
    return {
      headers: [
        { text: '', value: 'avatar' },
        { text: 'Nombre', value: 'nombre', class: 'purple--text' },
        { text: 'Grupo', value: 'grupo', class: 'purple--text'},
        { text: 'ID', value: 'id', class: 'purple--text'},
        { text: '', value: 'acciones' },
      ],
      items: [],
      inactiveItems: [],
      search: null,
      students: [],
      groups: [],
      showInactive: false,
      loading: true
    }
  },
  computed: {
    ...mapState(['institutionId', 'userData']),
  },
  async created() {
    this.setIsLogin(false)
    this.loading = true
    this.groups = await this.fetchResultsByEI('personas', 'grupo', this.institutionId)
    this.students = await this.fetchResultsByEI('personas', 'alumno', this.institutionId)
    await this.getStudents()
    this.loading = false
  },
  methods: {
    ...mapMutations(['setIsLogin']),
    async getStudents() {
      try {
        this.students.forEach(student => {
          const {
            primer_nombre: pn,
            segundo_nombre: sn,
            apellido_paterno: ap,
            apellido_materno: am
          } = student.datos_personales
          const fullName = `${pn} ${sn} ${ap} ${am}`
          const filterGroup = this.groups.filter(grp => {
            return grp.alumnos.find(std => std.id === student.id)
          })
          let group = 'Sin asignar'
          let groupId = null
          let groupImage = 'huellita.png'
          if (filterGroup && filterGroup.length > 0) {
            group = filterGroup[0].nombre_grupo
            groupId = filterGroup[0].id
            groupImage = filterGroup[0].imagen_grupo !== null ? filterGroup[0].imagen_grupo : 'huellita.png'
          }
          // console.log('Se encontró el grupo? ', group);
          const item = {
            avatar: groupImage,
            nombre: fullName,
            grupo: group,
            id: student.id,
            groupId: groupId,
            ...student
          }
          if (item.estatus_sistema) {
            this.items.push(item)
          } else {
            this.inactiveItems.push(item)
          }
        });
      } catch (error) {
        console.error('Error al obtener estudiantes. ', error);
      }
    },
    /**
     * Desactiva un alumno. Implica tambien removerlo del
     * grupo al que pertenezca si fuera el caso.
     * @param {number} studentId Id del alumno a desactivar
     */
    async disableStudent(studentId) {
      try {
        // console.log('value', studentId);
        const student = this.items.find(s => s.id === studentId)
        // console.log('Alumno seleccionado', student);
        const groupId = student.groupId
        if (groupId !== null) {
          await this.removeStudentOfGroup(studentId, groupId)
        }

        await this.disableDebts(studentId)

        const responseStudent = await this.postObj('/personas/delete-alumno', {
          id_alumno: studentId
        },
        'eliminar')
        if (responseStudent.mensaje) {
          this.items = this.items.filter(s => s.id !== studentId)
          this.inactiveItems.push(student)
        }
      } catch (error) {
        console.error('Error al intentar desactivar alumno', error);
      }
    },
    async removeStudentOfGroup(studentId, groupId) {
      try {
        const responseGroup = await this.postObj('/personas/remove-alumnos-grupo', {
          id_alumno: studentId,
          id_grupo: groupId
        },
        'remover')
        if (responseGroup.mensaje.includes('Alumno removido con éxito')) {
          // this.alertText = 'Alumno desasignado de grupo exitosamente'
        }
      } catch (error) {
        console.error('Error al intentar remover alumno de grupo', error);
      }
    },
    /**
     * Reactiva un alumno. Al reactivar se deben buscar los
     * adeudos que esten pendientes y reactivarlos si ese
     * fuera el caso
     * @param {number} studentId Id del alumno a reactivar
     */
    async enableStudent(studentId) {
      try {
        const student = this.inactiveItems.find(e => e.id === studentId)
        const response = await this.postObj('/personas/un-delete-alumno', {
          id_alumno: studentId
        },
        'reactivar')
        if (response.mensaje) {
          this.inactiveItems = this.inactiveItems.filter(e => e.id !== studentId)
          this.items.push(student)
        }

        await this.enableDebts(studentId)

      } catch (error) {
        console.error('Error al intentar reactivar un alumno', error);
      }
    },
    /**
     * Desactiva los adeudos de un alumno. Solo los pendientes de
     * pago y que no hayan vencido
     * @param {number} studentId Id del alumno al que le pertenecen los adeudos
     */
    async disableDebts(studentId) {
      try {
        const today = new Date()
        const todayWithTimeZero = this.getDateWithTimeZero(today)

        const responseDebts = await this.fetchResultsWithParams('administracion', 'aedudos-by-alumno', {
          id_alumno: studentId
        })
        if (responseDebts) {
          const allDebts = responseDebts
          const debtsToDisable = allDebts
            .filter(debt => debt.estatus.dato.includes('Pendiente de pago') || debt.estatus.dato.includes('Creado'))
            .filter(debt => {
              const vencimiento = this.getDateWithTimeZeroStr(debt.fecha_vencimiento)
              return vencimiento > todayWithTimeZero
            })
          // console.log('Adeudos a desactivar', debtsToDisable);
          if (debtsToDisable) {
            for (const debt of debtsToDisable) {
              const responseDebt = await this.postObj('/administracion/delete-adeudo', {
                id_adeudo: debt.id
              })
              if (responseDebt.mensaje) {
                // this.alertText = 'Adeudo desactivado exitosamente'
              }
            }
          }
        }
      } catch (error) {
        console.error('Error al intentar desactivar adeudos de alumno', error);
      }
    },
    /**
     * Desactiva los adeudos de un alumno.
     * Hasta el momento la única forma de desactivar un adeudo
     * es por medio de desactivar un alumno, por lo tanto lo que 
     * hacemos aqui es ir a buscar todos los adeudos de un alumno
     * y de esos filtramos los que tengan estatus_sistema == false
     * Los obtenidos se mandaran a activar uno a uno
     * @param {number} studentId Id del alumno al que le pertenecen los adeudos
     */
    async enableDebts(studentId) {
      try {
        const responseDebts = await this.fetchResultsWithParams('administracion', 'aedudos-by-alumno', {
          id_alumno: studentId
        })
        if (responseDebts) {
          const debtsToActivate = responseDebts.filter(debt => !debt.estatus_sistema)
          // console.log('Adeudos a reactivar', debtsToActivate);
          if (debtsToActivate) {
            for (const debt of debtsToActivate) {
              const responseDebt = await this.postObj('/administracion/activar-adeudo', {
                id_adeudo: debt.id
              })
              if (responseDebt.mensaje) {
                // this.alertText = 'Adeudo activado exitosamente'
              }
            }
          }
        }
      } catch (error) {
        console.error('Error al intentar activar adeudos de alumno', error);
      }
    }
  }
}
</script>
